<template>
  <!-- 我的 -->
  <div class="personCenContainer">
    <div class="personBg"></div>
    <div class="abslteBg">
      <div class="personTop"
           @click="checkPersonalData">
        <div class="personCont">
          <div class="presonAvar">
            <img style="border-radius: 100%"
                 :src="cappicurl" />
          </div>
          <div class="presonInfo">
            <div class="personCon">
              <div class="personCon_child">
                <h2>{{ userInfo.empname }}</h2>
                <p v-show="showempinfo">{{ $t('personCen.perTxt1') }}</p>
              </div>
            </div>
            <div class="prsonArrow">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
      <div class="personList">
        <div class="btn_group"
             v-for="(item, index) in cellList"
             :key="index">
          <div class="list"
               v-for="(it, idx) in item.origin"
               :key="idx">
            <van-cell :title="it.name"
                      center
                      is-link
                      @click="goToFuck(it)">
              <template #icon>
                <svg-icon :icon-class="it.iconName"></svg-icon>
              </template>
            </van-cell>
          </div>
          <!-- <div class="list"
               v-for="(it,idx) in item.origin"
               :key="idx"
               @click="changePsw">
            <div class="list-left">
              <svg-icon icon-class="personCen1_1"></svg-icon>修改登录密码
            </div>
            <div class="list-right">
              <van-icon name="arrow"
                        color="rgba(153, 153, 153, 1)"
                        size="0.5rem" />
            </div>
          </div>
          <div class="line"></div>-->
        </div>
      </div>
    </div>
    <van-dialog v-model="showQRcode"
                title
                :showConfirmButton="false">
      <van-icon name="cross"
                @click="closeEwm" />
      <div class="ewm">
        <qrcode :text="recommendUrl" />
      </div>
      <p>
        {{ $t('home.text7') }}
        <br />
        <!-- 进入面试题 -->
      </p>
    </van-dialog>
  </div>
</template>

<script>
import { getWxTopBtnList, getWxMyBtnList, getSalarySignParam, getScanEntryUrl } from '@api/wx.js'
import qrcode from '@components/qrcode'
export default {
  name: 'personCenContainer',
  components: {
    qrcode
  },
  inject: ['reload'],
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      cappicurl: require('@assets/img/loginLogo.png'),
      showQRcode: false,
      recommendUrl: '', // 内推二维码
      showempinfo: true, // 是否允许点击头像跳转到个人信息查看和编辑页面
      btnlist: [
        {
          id: 1,
          iconName: 'mine1',
          name: '',
          url: ''
        },
        {
          id: 2,
          iconName: 'mine2',
          name: '',
          url: ''
        },
        {
          id: 3,
          iconName: 'mine3',
          name: '',
          url: ''
        },
        {
          id: 4,
          iconName: 'mine4',
          name: '',
          url: ''
        },
        {
          id: 5,
          iconName: 'mine5',
          name: '',
          url: ''
        },
        {
          id: 6,
          iconName: 'mine6',
          name: '',
          url: ''
        },
        {
          id: 7,
          iconName: 'mine7',
          name: '',
          url: ''
        },
        {
          id: 8,
          iconName: 'mine8',
          name: '',
          url: ''
        },
        {
          id: 9,
          iconName: 'mine9',
          name: '',
          url: ''
        },
        {
          id: 10,
          iconName: 'mine10',
          name: '',
          url: ''
        },
        {
          id: 11,
          iconName: 'mine11',
          name: '',
          url: ''
        },
        {
          id: 12,
          iconName: 'mine12',
          name: '',
          url: ''
        },
        {
          id: 13,
          iconName: 'personCen1_6',
          name: '',
          url: ''
        },
        {
          id: 14,
          iconName: 'mine14',
          name: '',
          url: ''
        },
        {
          id: 15,
          iconName: 'mine15',
          name: '',
          url: ''
        },
        {
          id: 16,
          iconName: 'mine16',
          name: '',
          url: ''
        },
        {
          id: 17,
          iconName: 'mine17',
          name: '',
          url: ''
        },
        {
          id: 18,
          iconName: 'personCen1_1',
          name: '',
          url: ''
        },
        {
          id: 19,
          iconName: 'hrow1_11',
          name: '',
          url: ''
        },
        {
          id: 20,
          iconName: 'personCen1_2',
          name: '',
          url: ''
        },
        {
          id: 21,
          iconName: 'personCen1_3',
          name: '',
          url: ''
        },
        {
          id: 22,
          iconName: 'personCen1_4',
          name: '',
          url: ''
        },
        {
          id: 23,
          iconName: 'personCen1_5',
          name: '',
          url: ''
        },
        {
          id: 24,
          iconName: 'personCen1_6',
          name: '',
          url: ''
        },
        {
          id: 25,
          iconName: 'personCen1_7',
          name: '',
          url: ''
        }
      ], // 【我的】页面各个各个功能按钮的图标
      cellList: [],
      V3url:
        //process.env.NODE_ENV === 'production' ? window.V3url :
        './'
    }
  },
  created () {
    this.getWxTopBtnList()
    this.getMyBtnList()
  },
  mounted () {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[4].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    // 6.返回【我的】页签按钮列表接口
    getMyBtnList () {
      getWxMyBtnList({
        username: this.userInfo.username
      }).then(res => {
        this.showempinfo = res.baseinfo[0].showempinfo
        let afterData = []
        let tempArr = []
        let resData = res.btnlist
        // let ary = resData.filter(item => { return item.pjr_empname + item.checkflagname })
        if (resData.length > 0)
        {
          for (let i = 0; i < resData.length; i++)
          {
            this.btnlist.forEach(ele => {
              if (ele.id == resData[i].id)
              {
                resData[i].iconName = ele.iconName
              }
            })
            // 处理url
            if (resData[i].url.indexOf('@v3url') != -1)
            {
              resData[i].url =
                (window.V3url ? window.V3url : '') +
                resData[i].url.split('@v3url')[1]
            }
            // 分组
            if (tempArr.indexOf(resData[i].groupname) === -1)
            {
              afterData.push({
                groupname: resData[i].groupname,
                origin: [resData[i]]
              })
              tempArr.push(resData[i].groupname)
            } else
            {
              for (let j = 0; j < afterData.length; j++)
              {
                if (afterData[j].groupname == resData[i].groupname)
                {
                  afterData[j].origin.push(resData[i])
                  break
                }
              }
            }
          }
        }
        this.cellList = afterData
        console.log(this.cellList, 'this.cellList')
      })
    },
    // 切换账号 尽可能不要使用路由的方式 可能导致数据不刷新 出错
    goLogin () {
      localStorage.setItem('v8_login_login', '0')
      localStorage.removeItem('userid')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('OpenId')
      sessionStorage.setItem('out', '1')
      this.$nextTick(() => {
        // this.$router.replace('/login')
        window.location.href = '/login'
      })
    },
    changePsw () {
      this.$router.push('/changePassword')
    },
    changeSalPsw () {
      this.$router.push('/changeSalaryPsw')
      // this.reload = false
      // setTimeout(() => {
      //   this.reload = true
      //   this.$router.push({
      //     path: '/jump',
      //     query: {
      //       url: (window.V3url ? window.V3url : '') + '/weixinlocal/my/modifygzpwd.html',
      //       time: new Date().getTime()
      //     }
      //   })
      // })
    },
    getWxTopBtnList () {
      getWxTopBtnList({
        username: this.userInfo.username
      }).then(res => {
        console.log(res, 'rrr')
        if (res.panellist.length > 0)
        {
          // 显示头像
          var url = res.panellist[0].cappicurl + '?v=' + Date.parse(new Date())
          let img = new Image()
          img.src = url
          img.onload = () => {
            //判断图片是否加载成功
            this.cappicurl = url
          }
        }
      })
    },
    qrcode () {
      // 内推二维码
      // this.recommendUrl =
      //   (window.VUE_APP_DOMAIN
      //     ? window.VUE_APP_DOMAIN
      //     : "https://v8.hyclound.cn:8090") +
      //   "/#/scanEntry?module=304&restype=内推&tj_empid=" +
      //   this.userInfo.empid;
      // this.showQRcode = true;
      this.$router.push('/reqrcode')
    },
    closeEwm () {
      this.showQRcode = false
    },
    // 跳转到到内部员简历
    async goLocalEmpEntry () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      let url = ''
      await getScanEntryUrl().then(res => {
        if (this.$getObject(res) == 'String')
        {
          url = res +
            '/LocalEmpEntry?module=201&localempid=' +
            this.userInfo.empid +
            '&localuserid=' +
            this.userInfo.userid
        }
      })
      const path1 = location.origin
      // const path2 = url.toLowerCase().split('://')[0] + ':'
      if (url.toLowerCase().indexOf(path1) > -1)
      {
        //相同协议，那么内嵌打开
        this.gReqEvent(url)
      } else
      {
        //协议不同，跳转
        if (isAndroid)
        {
          //android终端
          window.open(url)
        } else if (isiOS)
        {
          //ios终端
          window.location.href = url
        } else
        {
          window.open(url)
        }
      }
    },
    gReqEvent (url) {
      this.reload = false
      setTimeout(() => {
        this.reload = true
        this.$router.push({
          path: '/jump',
          query: {
            url: (window.V3url ? window.V3url : '') + url,
            time: new Date().getTime()
          }
        })
      })
    },
    // 查看--个人资料
    checkPersonalData () {
      if (this.showempinfo)
      {
        this.$router.push('/personalData')
      }
    },
    // SBWY
    goToFuck (val) {
      if (
        val.id == 10 &&
        (window.use_new_cap == 1 || window.use_new_cap == '1')
      )
      {
        // 人脸采集
        this.$router.push('/faceCollection')
      } else if (val.id == 24)
      {
        // 订餐
        this.$router.push('/weChatOrdering')
      } else if (val.id == 13)
      {
        // 简易订餐
        this.$router.push({
          path: '/simple',
          query: { fromH5: true, name: val.name }
        })
      } else if (val.id == 20)
      {
        // 切换账号
        this.goLogin()
      } else if (val.id == 18)
      {
        // 修改登录密码
        this.changePsw()
      } else if (val.id == 19)
      {
        // 修改工资密码
        this.changeSalPsw()
      } else if (val.id == 21)
      {
        // 内推二维码
        this.qrcode()
      } else if (val.id == 22)
      {
        // 我的简历
        this.goLocalEmpEntry()
      } else if (val.id == 17)
      {
        // 工资签收
        this.salarySigning()
      } else if (val.id == 23)
      {
        // 我的证件
        this.$router.push({
          path: '/MyIdCard'
        })
      } else
      {
        let url = ''
        if (val.url.indexOf('http') > -1)
        {
          url = val.url
        } else
        {
          url = this.V3url + val.url
        }
        if (url.indexOf('yidongqiandao') > -1)
        {
          window.location.href = url
        } else
        {
          this.$router.push({
            path: '/jump',
            query: { url: url, time: new Date().getTime() }
          })
        }
        this.reload()
      }
    },
    // 工资签收
    salarySigning () {
      getSalarySignParam({
        empcode: this.userInfo.empcode
      }).then(res => {
        let firstchangepwd = res.data[0].firstchangepwd
        let isfirstlogin = res.data[0].isfirstlogin
        let nosign = res.data[0].nosign
        if (isfirstlogin && firstchangepwd)
        {
          // 如果是首次打开并且 首次打开要强制修改密码 就去改密码界面
          this.$router.push({
            path: '/changeSalaryPsw',
            query: {
              from: 'checkSalaryPwd',
              firstchangepwd: firstchangepwd,
              isfirstlogin: isfirstlogin,
              nosign: nosign
            }
          })
        } else
        {
          this.$router.push({ path: '/salarySiginPassword' })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.personCenContainer {
  width: 100%;
  height: calc(100vh - 1.89333rem);
  position: relative;
}
.personBg {
  width: 100%;
  height: 142px;
  background: #2b8df0;
  position: relative;
  z-index: 1;
}
.abslteBg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  overflow: hidden;
}
.personTop {
  // width: 92%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  border-radius: 16px;
  margin: 0 30px;
  margin-top: 40px;
  .personCont {
    padding: 40px 40px 40px 40px;
    display: flex;
  }
}
.presonAvar {
  width: 122px;
  min-width: 122px;
  height: 122px;
  border-radius: 122px;
  border: 2px solid #2b8df0;
  img {
    width: 100%;
    height: 100%;
  }
}
.presonInfo {
  width: 100%;
  height: 122px;
  display: flex;
}
.personCon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 15px;
  .personCon_child {
    width: 100%;
  }
  h2 {
    margin: 0;
    font-size: 34px;
    padding: 0;
    line-height: 48px;
    color: #333333;
    width: 100%;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
    padding: 0;
    color: #999999;
    font-size: 24px;
    width: 100%;
  }
}
.prsonArrow {
  width: 50px;
  min-width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
}
.personList {
  width: 100%;
  height: calc(100% - 250px);
  overflow: auto;
  // background: #fff;
  padding-left: 4%;
  padding-right: 4%;
  margin-top: 30px;
  .btn_group:first-child {
    margin-top: 0;
  }
  .btn_group {
    margin: 30px 0;
    background: #fff;
    .list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid rgba(204, 204, 204, 1);
      .van-cell {
        border-bottom: 1px solid rgba(204, 204, 204, 1);
        .svg-icon {
          width: 1.2em;
          height: 1.2em;
          margin-right: 0.5em;
        }
        .van-cell__title {
          font-size: 32px;
          color: #000000;
        }
      }
      // .list-left {
      //   margin: 0;
      //   padding: 0;
      //   font-size: 32px;
      //   color: #000000;
      //   line-height: 1.25rem;
      //   svg {
      //     margin-right: 1em;
      //   }
      // }
    }
    .line {
      height: 1px;
      background: rgba(204, 204, 204, 1);
      width: 100%;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }
}
.van-dialog {
  /deep/.van-dialog__content {
    position: relative;
    height: 100%;
    text-align: center;
    .ewm {
      width: 4.52rem;
      margin: 0 auto;
      padding-top: 1rem;
      min-height: 5.52rem;
      img {
        width: 4.52rem;
        height: 4.52rem;
      }
    }
    p {
      width: 100%;
      margin-top: 0.5rem;
    }
    .van-icon {
      width: 28px;
      height: 28px;
      position: absolute;
      right: 40px;
      top: 32px;
    }
  }
}
</style>
